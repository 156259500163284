export const ACTIVITIES_QUERY = `
query ($age: [QueryArgument] ) {
	entries(section: "activities", relatedTo: $age, orderBy: "title ASC") {
        id,
        title,
        slug,
        url,
        ...on activities_activities_Entry {
            ageCategory {
                slug,
            }
        }
    }
}
`

export const HERO_SLIDER_QUERY =
`
query ($entryId: [QueryArgument]) {
  entry(id: $entryId) {
    title,
    slug,
    id,
    ... on homepage_homepage_Entry {
      heroBuilder {
        ... on heroBuilder_heroSlider_BlockType {
          sliders {
            ... on sliders_BlockType {
              heading
              description
              target {
                url
              }
              image {
                ... on sliders_Asset {
                  optimizeSlider {
                    src
                    srcset
                    srcWebp
                    srcsetWebp
                    placeholderImage
                  }
                }
              }
            }
          }
        }
      }
    }
    ... on pages_specialEventsLanding_Entry {
      heroBuilder {
        ... on heroBuilder_heroSlider_BlockType {
          sliders {
            ... on sliders_BlockType {
              heading
              description
              target {
                url
              }
              image {
                ... on sliders_Asset {
                  optimizeSlider {
                    src
                    srcset
                    srcWebp
                    srcsetWebp
                    placeholderImage
                  }
                }
              }
            }
          }
        }
      }
    }
    ... on pages_parksAndTrailsLanding_Entry {
      heroBuilder {
        ... on heroBuilder_heroSlider_BlockType {
          sliders {
            ... on sliders_BlockType {
              heading
              description
              target {
                url
              }
              image {
                ... on sliders_Asset {
                  optimizeSlider {
                    src
                    srcset
                    srcWebp
                    srcsetWebp
                    placeholderImage
                  }
                }
              }
            }
          }
        }
      }
    }
    ... on pages_reservationsLandingParkField_Entry {
      heroBuilder {
        ... on heroBuilder_heroSlider_BlockType {
          sliders {
            ... on sliders_BlockType {
              heading
              description
              target {
                url
              }
              image {
                ... on sliders_Asset {
                  optimizeSlider {
                    src
                    srcset
                    srcWebp
                    srcsetWebp
                    placeholderImage
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`

export const NAVIGATION_QUERY =
`
{
  entries(level: 1, section: "pages") {
    id
    slug
    url
    level
    ...on pages_contentPage_Entry {
      linkTextTop
      linkTextBottom
    }
    ...on pages_ratesLanding_Entry {
      linkTextTop
      linkTextBottom
    }
    ...on pages_parksAndTrailsLanding_Entry {
      linkTextTop
      linkTextBottom
    }
    children: children {
      id
      url
      title
      slug
      level
      typeHandle
      ...on pages_contentPage_Entry {
        hideFromNavigation
      }
      ...on pages_policyLanding_Entry {
        hideFromNavigation
      }
      ...on pages_link_Entry {
        linkBuilder {
          ...on linkBuilder_linkToEntry_BlockType {
            id
            linkText
            entrySelect {
              id
              url
            }
          }
          ...on linkBuilder_linkToExternal_BlockType {
            id
            linkText
            externalUrl
          }
          ...on linkBuilder_linkToActivity_BlockType {
            id
            linkText
            ageGroup
            activitySelect {
              id
              url
            }
          }
        }
      }
      ...on pages_activities_Entry {
        ageCategory {
          id
          slug
        }
        ctas: navigationCallToAction {
          ...on navigationCallToAction_BlockType {
            id
            fa: fontAwesomeIcon {
              value
            }
            text: linkText
            entry: linkToEntry {
              url
            }
            url: linkToExternalWebsite
          }
        }
      }
    }
  }
}
`

export const EVENT_DETAIL = `
query ($id: [QueryArgument]) {
  entry(id: $id) {
    title
    slug
    url
    ...on activityEvents_activityEvents_Entry {
      activitySelectReverse {
        title
        id
      }
      ageGroups
      description
      additionalAgeGroupInfo
      startDate @formatDateTime(format: "M j")
      endDate @formatDateTime(format: "M j")
      locationSelect {
        id
        title
        url
        level
        ...on facilities_primary_Entry {
          title
          abbreviation
        }
      }
      location
      registrationUrl
      daysAndTimesWithInstructor {
        ...on daysAndTimesWithInstructor_BlockType {
          startTime
          endTime
          day
          instructor {
            id
            title
            url
          }
        }
      }
      flexDates {
        ...on flexDates_BlockType {
          classEvent
          date @formatDateTime(format: "M j")
          startTime @formatDateTime(format: "g:i a")
          endTime @formatDateTime(format: "g:i a")
          canceled
        }
      }
      activityEventsCost {
        ...on activityEventsCost_BlockType {
          rateType
          generalRate
          passholderRate
          onlineRate
          purchaseUrl
          admissionAlsoRequired
          displayAsDropIn
        }
      }
      contacts {
        ...on contacts_BlockType {
          existingContact {
            title
            url
            ...on people_people_Entry {
              isInstructor
            }
          }
          contactName
          title
          address
          phone
          email
          displayAsInstructor
        }
      }
      otherLinks {
        ...on otherLinks_BlockType {
          linkHeading
          linkText
          linkToExternalWebsite
          linkToEntry {
            url
          }
          linkToFile {
            url
          }
          locationSelect {
            url
          }
        }
      }
    }
    ...on leagues_leagues_Entry {
      ageGroup
      description
      additionalAgeGroupInfo
      startDate @formatDateTime(format: "M j")
      endDate @formatDateTime(format: "M j")
      locationSelect {
        id
        title
        url
        level
        ...on facilities_primary_Entry {
          title
          abbreviation
        }
      }
      location
      registrationUrl
      websiteLink
      daysAndTimes {
        ...on daysAndTimes_BlockType {
          day
          startTime
          endTime
          group
        }
      }
      leagueCosts {
        ...on leagueCosts_BlockType {
          rateType
          generalRate
          passholderRate
          onlineRate
          admissionAlsoRequired
        }
      }
      contacts {
        ...on contacts_BlockType {
          existingContact {
            id
            title
            url
          }
          contactName
          title
          address
          phone
          email
          displayAsInstructor
        }
      }
      otherLinks {
        ...on otherLinks_BlockType {
          linkHeading
          linkText
          linkToExternalWebsite
          linkToEntry {
            url
          }
          linkToFile {
            url
          }
          locationSelect {
            url
          }
        }
      }
      messages {
        ...on messages_BlockType {
          messageTitle
          message
        }
      }
    }
    ...on activities_activities_Entry {
      dropInInformation {
        ...on dropInInformation_dropIns_BlockType {
          id
          dropInsName
          registrationUrl
          description
          startDate @formatDateTime(format: "M j")
          endDate @formatDateTime(format: "M j")
          ageGroups
          locationSelect {
            title
            url
            ...on facilities_primary_Entry {
              title
              abbreviation
            }
          }
          daysTimes {
            ...on daysTimes_BlockType {
              day
              startTime @formatDateTime(format: "g:i a")
              endTime @formatDateTime(format: "g:i a")
            }
          }
          costs {
            ...on costs_BlockType {
              rateType
              generalRate
              onlineRate
              purchaseUrl
            }
          }
        }
      }
    }
    ...on events_events_Entry {
      description,
      website: websiteLink,
    }
    ...on boardMeetings_boardMeetings_Entry {
      description,
      meetingLocation,
      meetingAgenda {
        url
      }
    }
  }
}
`

export const NEWS_QUERY = `
    {
  entries(section: "newsBlog", orderBy: "postDate DESC", type: "news") {
    title
    slug
    url
    ...on newsBlog_news_Entry {
      description
      isFeatured
      newsCategory {
        id
      }
      teaserImage {
        ...on teasers_Asset {
          optimizeTeasers {
            src
            srcset
            srcWebp
            srcsetWebp
            placeholderImage
          }
        }
      }
      otherLinks {
        ...on otherLinks_BlockType {
          linkHeading
          linkText
          linkToExternalWebsite
          linkToEntry {
            url
          }
          linkToFile {
            url
          }
          locationSelect {
            url
          }
        }
      }
    }
  }
}
`

export const NEWS_RELATED_QUERY = `
    query ($id: [QueryArgument]) {
  entries(section: "newsBlog", orderBy: "postDate DESC", type: "news", relatedTo: $id) {
    title
    slug
    url
    ...on newsBlog_news_Entry {
      description
      newsCategory {
        id
      }
      teaserImage {
        ...on teasers_Asset {
          optimizeTeasers {
            src
            srcset
            srcWebp
            srcsetWebp
            placeholderImage
          }
        }
      }
      otherLinks {
        ...on otherLinks_BlockType {
          linkHeading
          linkText
          linkToExternalWebsite
          linkToEntry {
            url
          }
          linkToFile {
            url
          }
          locationSelect {
            url
          }
        }
      }
    }
  }
}
`

export const NEWS_CATEGORIES_QUERY =
`
    query {
        categories( group: "newsCategories", level: 1 ) {
            id,
            title,
            slug,
        }
    }
`

export const PLACEHOLDERS_QUERY =
`
    query {
        globalSet(handle: "placeholders") {
            ...on placeholders_GlobalSet {
                teaserPlaceholder {
                    ...on teasers_Asset {
                        optimizeTeasers {
                            src,
                            srcset,
                            srcWebp,
                            srcsetWebp,
                            placeholderImage,
                        }
                    }
                },
                articlePlaceholder {
                    ...on article_Asset {
                        optimizeArticles {
                            src,
                            srcset,
                            srcWebp,
                            srcsetWebp,
                            placeholderImage,
                        }
                    }
                },
                heroPlaceholder {
                    ...on heroImages_Asset {
                      optimizeHeroes {
                            src,
                            srcset,
                            srcWebp,
                            srcsetWebp,
                            placeholderImage,
                        }
                    }
                },
                profilePhotoPlaceholder {
                    ...on profiles_Asset {
                        optimizeProfileImages {
                            src,
                            srcset,
                            srcWebp,
                            srcsetWebp,
                            placeholderImage,
                        }
                    }
                },
                sliderPlaceholder {
                    ...on sliders_Asset {
                        optimizeSlider {
                            src,
                            srcset,
                            srcWebp,
                            srcsetWebp,
                            placeholderImage,
                        }
                    }
                },
            }
        }
    }
`

export const CLOSURE_QUERY = 
`
    query($id: [QueryArgument]) {
      entry(id: $id) {
        id
        title
        slug
        dateUpdated
        ...on updates_closures_Entry {
          closureSchedule {
            closureStartDate @formatDateTime (format: "Y-m-d", timezone: "America/Denver"),
            closureStartTime @formatDateTime (format: "H:i", timezone: "America/Denver"),
            closureEndDate @formatDateTime (format: "Y-m-d", timezone: "America/Denver"),
            closureEndTime @formatDateTime (format: "H:i", timezone: "America/Denver"),
          },
          closureInformation,
          closeImmediately,
          notification,
        }
      }
    }
`

export const STATUSES_QUERY =
`
query ($id: [QueryArgument], $type: [String], $featured: Boolean, $parks: Boolean) {
  entries(section: "updates", relatedTo: $id, type: $type, isFeatured: $featured, displayOnParkLanding: $parks) {
    id
    title
    slug
    dateUpdated
    ...on updates_closures_Entry {
      closureSchedule {
        closureStartDate @formatDateTime (format: "Y-m-d", timezone: "America/Denver"),
        closureStartTime @formatDateTime (format: "H:i", timezone: "America/Denver"),
        closureEndDate @formatDateTime (format: "Y-m-d", timezone: "America/Denver"),
        closureEndTime @formatDateTime (format: "H:i", timezone: "America/Denver"),
      },
      closureInformation,
      closeImmediately,
      notification,
    }
    ...on updates_status_Entry {
      statusSelect
      facilityName
      currentStatus
      projectedOpening
      updated
      sportsActivitiesSelect {
        id
        title
        url
      }
      facilitiesSelect {
        id
        title
        url
      }
      trailSelect {
        id
        title
        url
      }
      parkSelect {
        id
        title
        url
      }
      linkBuilder {
        ...on linkBuilder_linkToEntry_BlockType {
          id
          linkText
          entrySelect {
            id
            url
            title
          }
        }
        ...on linkBuilder_linkToExternal_BlockType {
          id
          linkText
          externalUrl
        }
        ...on linkBuilder_linkToActivity_BlockType {
            id
            linkText
            ageGroup
            activitySelect {
              id
              url
            }
          }
      }
    }
  }
}
`