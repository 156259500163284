// Configure the events api endpoint
export const configureEventsApi = (url) => ({
    baseURL: url,
});

// Execute a Events query by sending an XHR to our api endpoint
export const executeEventsQuery = async(api, options, callback) => {
    // Execute the GQL query
    try {
        const response = await api.get('', {
            params: {
                id: options.id ?? null,
            }     
        })

        if (callback && response.data) {
            callback(response.data);
        }

        // Log any errors
        if (response.errors) {
            console.error(response.errors);
        }

    } catch (error) {
        // TODO: fetch all events after 6s
        console.error(error);
    }
};