import axios from 'axios';
import { configureXhrApi, executeXhr } from '../utils/xhr.js';
import { configureGqlApi, executeGqlQuery } from '../utils/gql.js';
import { configureEventsApi, executeEventsQuery } from '../utils/events.js';
import { ACTIVITIES_QUERY, CLOSURE_QUERY, EVENT_DETAIL, HERO_SLIDER_QUERY, NAVIGATION_QUERY, NEWS_QUERY, NEWS_RELATED_QUERY, NEWS_CATEGORIES_QUERY, PLACEHOLDERS_QUERY, STATUSES_QUERY } from '../data/queries.js';

const CSRF_ENDPOINT = '/actions/site-module/csrf/get-csrf';
const TOKEN_ENDPOINT = '/actions/site-module/csrf/get-gql-token';
const GRAPHQL_ENDPOINT = '/api';
const EVENTS_ENDPOINT = '/actions/site-module/events/get-events';

// Fetch & commit the CSRF token
export const fetchCsrf = async({commit}) => {
    const api = axios.create(configureXhrApi(CSRF_ENDPOINT));
    let variables = {
    };
    // Execute the XHR
    await executeXhr(api, variables, (data) => {
        commit('setCsrf', data);
    });
};

// Fetch & commit the GraphQL token
export const fetchGqlToken = async({commit, state}) => {
    const api = axios.create(configureXhrApi(TOKEN_ENDPOINT));
    let variables = {
        ...(state.csrf && { [state.csrf.name]: state.csrf.value }),
    };
    // Execute the XHR
    await executeXhr(api, variables, (data) => {
        commit('setGqlToken', data);
    });
};

// fetch hero slides - only per block.
export const fetchHeroSlides = async({commit, state}, options) => {

    const token = state.gqlToken ? state.gqlToken.token : null;

    // configure the API endpoint
    const api = axios.create(configureGqlApi(GRAPHQL_ENDPOINT, token));

    let variables = options;

    // Execute the GQL Query
    await executeGqlQuery(api, HERO_SLIDER_QUERY, variables, (data) => {

        if (data.entry.heroBuilder) {
            commit('setSlides', { data: data.entry.heroBuilder[0].sliders, id: options.entryId });
        }

    })
}

// build the base toplevel state
export const fetchNavigation = async({commit, state, dispatch}) => {
    const token = state.gqlToken ? state.gqlToken.token : null;

    // configure the API endpoint
    const api = axios.create(configureGqlApi(GRAPHQL_ENDPOINT, token));

    let variables = {};

    // Execute the GQL Query
    await executeGqlQuery(api, NAVIGATION_QUERY, variables, (data) => {

        if (data.entries) {
            commit('setNavigation', data.entries);
        }

    })

    state.navigation.forEach( (item) => {
        if (item.children) {
            item.children.forEach( async (child) => {
                if ( child.typeHandle === 'activities' ) {
                    await dispatch('fetchActivities', { id: child.id, age: child.ageCategory[0].id });
                }
            })
        }
    })
}

// fetch the news Items
export const fetchNews = async({commit, state}) => {
    const token = state.gqlToken ? state.gqlToken.token : null;

    // configure the API endpoint
    const api = axios.create(configureGqlApi(GRAPHQL_ENDPOINT, token));

    let variables = {};

    // Execute the GQL Query
    await executeGqlQuery(api, NEWS_QUERY, variables, (data) => {
        if (data.entries) {
            commit('setNews', data.entries);
        }
    })
}

// fetch the news categories
export const fetchNewsCategories = async({commit, state}) => {
    const token = state.gqlToken ? state.gqlToken.token : null;

    // configure the API endpoint
    const api = axios.create(configureGqlApi(GRAPHQL_ENDPOINT, token));

    let variables = {};

    // Execute the GQL Query
    await executeGqlQuery(api, NEWS_CATEGORIES_QUERY, variables, (data) => {
        if (data.categories) {
            commit('setNewsCategories', data.categories);
        }
    })
}

export const fetchNewsRelated = async({commit, state}, options) => {
    const token = state.gqlToken ? state.gqlToken.token : null;

    // configure the API endpoint
    const api = axios.create(configureGqlApi(GRAPHQL_ENDPOINT, token));

    let variables = {
        id: options ? options.id : null,
    };

    // Execute the GQL Query
    await executeGqlQuery(api, NEWS_RELATED_QUERY, variables, (data) => {
        if (data.entries) {
            commit('setNewsRelated', data.entries);
        }
    })
}

// set the active state of the navigation
export const setNavigationState = async({commit, state}, options) => {

    if ( options.level === 2 ) {

        const navigation = Object.entries(state.navigation).map(entry => {
            let item = entry[1];
            return {
                ...item,
                'active': item.id === options.id ? options.active : false,
            }
        })

        commit('setNavigation', navigation);

    }

    if ( options.level === 3 ) {

        const navigation = Object.entries(state.navigation).map(entry => {
            let item = entry[1]
            return {
                ...item,
                'children': item.children ? Object.entries(item.children).map(child => {
                    let childItem = child[1]
                    return {
                        ...childItem,
                        'active': childItem.id === options.id ? options.active : false,
                    }
                }) : null,
            }
        })

        commit('setNavigation', navigation);

    }

}

// fetch placeholders
export const fetchPlaceholders = async({commit, state}) => {
    const token = state.gqlToken ? state.gqlToken.token : null;

    // configure the API endpoint
    const api = axios.create(configureGqlApi(GRAPHQL_ENDPOINT, token));

    let variables = {};

    // Execute the GQL Query
    await executeGqlQuery(api, PLACEHOLDERS_QUERY, variables, (data) => {
        if (data.globalSet) {
            commit('setPlaceholders', data.globalSet);
        }
    })
}

// fetch statuses
export const fetchStatuses = async({commit,state}, options) => {
    const token = state.gqlToken ? state.gqlToken.token : null;

    // configure the API endpoint
    const api = axios.create(configureGqlApi(GRAPHQL_ENDPOINT, token))

    let variables = {
        id: options.id ? options.id : null,
        type: options.type,
        featured: options.featured ? true : null,
        parks: options.parks ? options.parks : null,
    }

    // Execute the GQL Query
    await executeGqlQuery(api, STATUSES_QUERY, variables, (data) => {
        if (data.entries) {
            
            if ( options.type === 'closures' ) {
                commit('setClosures', data.entries);
            } else if ( options.type === 'status' && options.featured ) {
                commit('setFeaturedStatuses', data.entries);
            } else {
                commit('setStatuses', data.entries);
            }

        }
    })
}

// fetch closureDetail
export const fetchClosureDetail = async({commit,state}, options) => {
    const token = state.gqlToken ? state.gqlToken.token : null;

    const api = axios.create(configureGqlApi(GRAPHQL_ENDPOINT, token))

    let variables = {
        id: options.id,
    }

    // Execute Query

    await executeGqlQuery(api, CLOSURE_QUERY, variables, (payload) => {
        if ( payload.entry ) {

            commit('setClosure', payload.entry)

        } 
    })
}

// fetch activities
export const fetchActivities = async({commit, state}, options) => {
    const token = state.gqlToken ? state.gqlToken.token : null;

    // configure the API endpoint
    const api = axios.create(configureGqlApi(GRAPHQL_ENDPOINT, token))

    let variables = {
        age: options.age
    }

    // Execute the GQL Query
    await executeGqlQuery(api, ACTIVITIES_QUERY, variables, (data) => {

        if (data.entries) {

            const navigation = Object.entries(state.navigation).map(entry => {
                let item = entry[1]
                return {
                    ...item,
                    'children': item.children ? Object.entries(item.children).map(child => {
                        let childItem = child[1];
                        let entries = null;

                        if ( childItem !== null && childItem.id === options.id ) {

                            entries = {
                                ...childItem,
                                'activities': data.entries,
                            }

                        } else {

                            entries = {
                                ...childItem,
                            }

                        }

                        return entries;

                    }) : null,
                }
            })

            commit('setNavigation', navigation);
        }

    })
}

// Fetch Calendar Events
export const fetchEvents = async({commit, state}, options) => {

    const api = axios.create(configureEventsApi(EVENTS_ENDPOINT));

    let variables = {
        ...(state.csrf && { [state.csrf.name]: state.csrf.value }),
        id: options.id,
    };

    if ( options.facility ) {

        let searchForm = {
            activities: [],
            ages: [],
            locations: [options.facility],
            news: state.searchForm.news,
        }

        commit('setSearch', searchForm);

    } else {

        let searchForm = {
            activities: [],
            ages: [],
            locations: [],
            news: state.searchForm.news,
        }

        commit('setSearch', searchForm);

    }
    
    // Execute the XHR
    await executeEventsQuery(api, variables, (data) => {

        if (data) {
            commit('setEvents', data.events);
        }

    });

}

// Fetch Event Detail
export const fetchEventDetail = async({commit, state}, options) => {

    const token = state.gqlToken ? state.gqlToken.token : null;

    // configure the API endpoint
    const api = axios.create(configureGqlApi(GRAPHQL_ENDPOINT, token))

    let variables = {
        id: options.id
    }

     // Execute the GQL Query
    await executeGqlQuery(api, EVENT_DETAIL, variables, (data) => {

        if (data.entry) {
            commit('setEventDetail', data.entry);
        }

    })

}