// Import our CSS
import './assets/fonts.js';

import Vue from 'vue';
import { FocusTrap } from 'focus-trap-vue';
import { createStore } from './stores/store.js';
//import { createLoadingState } from './utils/wait.js';
import { mapGetters } from 'vuex';

// Register FocusTrap component globally
Vue.component('FocusTrap', FocusTrap as any);

const main = async () => {
    // Async load the vue module
    const [ Vue, Lazysizes ] = await Promise.all([
        import(/* webpackChunkName: "vue" */ 'vue'),
        import(/* webpackChunkName: "lazysizes" */ 'lazysizes'),
    ])

    const store = await createStore(Vue.default);
    //const wait = await createLoadingState(Vue.default);

    // Create our vue instance
    const vm = new Vue.default({
        el: "#page-container",
        store,
        components: {
            'activities--today': () => import(/* webpackChunkName: "activities--today" */ '../vue/organisms/activities/activities--today.vue'),
            'navigation--main': () => import(/* webpackChunkName: "navigation--main" */ '../vue/organisms/navigations/navigation--main.vue'),
            'image--gallery': () => import(/* webpackChunkName: "image--gallery" */ '../vue/organisms/images/image--gallery.vue'),
            'cards--news': () => import(/* webpackChunkName: "cards--news" */ '../vue/organisms/cards/cards--news.vue'),
            'slider--hero': () => import(/* webpackChunkName: "slider--hero" */ '../vue/organisms/sliders/slider--hero.vue'),
            'updates--status': () => import(/* webpackChunkName: "updates--status" */ '../vue/organisms/updates/updates--status.vue'),
            'updates--closures': () => import(/* webpackChunkName: "updates--closures" */ '../vue/organisms/updates/updates--closures.vue'),
            'modal--main': () => import(/* webpackChunkName: "modal--main" */ '../vue/organisms/modals/modal--main.vue'),
            'link--classes': () => import(/* webpackChunkName: "link--classes" */ '../vue/atoms/links/link--classes.vue'),
            'calendar--events': () => import(/* webpackChunkName: "calendar--events" */ '../vue/organisms/calendars/calendar--events.vue'),
        },

        computed: {
            ...mapGetters([
                'getCsrf',
                'getGqlToken',
                'getPlaceholders',
                'getVersion',
            ]),
        },

        methods: {
            printPage() {
                window.print();
            },

            async clearStorage() {
                if ( localStorage.getItem('aspen') ) {

                    if ( this.getVersion !== '1.1.1' ) {
                        await localStorage.removeItem('aspen');
                    }
                }
            }
        },

        async mounted() {

            this.clearStorage();

            // Get the CSRF param to verify submissions before attempting any other queries
            if ( !this.getGqlToken ) {
                await this.$store.dispatch('fetchCsrf');
            }

            // Wait for the GQL token before attempting GQL queries
            if ( !this.getGqlToken ) {
                await this.$store.dispatch('fetchGqlToken');
            }

            await this.$store.dispatch('fetchPlaceholders');

        },

    });

    return vm;
};

// Execute async function
main().then( (vm) => {});

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
    module.hot.accept();
}