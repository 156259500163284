 import * as actions from './actions.js';
import * as getters from './getters.js';
import * as mutations from './mutations.js';

// Main Store
export const createStore = async(Vue) => {
    const { default: Vuex } = await import(/* webpackChunkName: "vuex" */ 'vuex');
    const { default: createPersistedState } = await import(/* webpackChunkName: "vuex-persistedstate" */ 'vuex-persistedstate');
    Vue.use(Vuex);
    return new Vuex.Store({
        state: {
            activeChild: null,
            closures: null,
            closure: null,
            csrf: null,
            events: null,
            eventDetail: null,
            featuredData: {},
            featuredStatuses: null,
            filters: {
                news: ['Adult Activities'],
            },
            placeholders: null,
            gqlToken: null,
            navigation: null,
            news: {
                all: null,
                related: null,
            },
            newsCategories: null,
            menuOpen: false,
            searchForm: {
                locations: [],
                ages: [],
                activities: [],
            },
            selectedCategories: {
                news: ['47100'],       // default selected news category
            },
            slides: null,
            statuses: null,
            slideouts: null,
            version: '1.0.1',
        },
        getters,
        mutations,
        actions,
        modules: {},
        plugins: [
            createPersistedState({
                key: 'aspen',
            }),
        ]
    })
}