import { dom, library } from '@fortawesome/fontawesome-svg-core';
import * as SolidIcons from '@fortawesome/free-solid-svg-icons';
import * as RegularIcons from '@fortawesome/free-regular-svg-icons';
import * as BrandIcons from '@fortawesome/free-brands-svg-icons';

const solidIconList = Object
    .keys(SolidIcons)
    .filter(key => key !== "fas" && key !== "prefix")
    .map(icon => SolidIcons[icon])

const regularIconList = Object
    .keys(RegularIcons)
    .filter(key => key !== "far" && key !== "prefix")
    .map(icon => RegularIcons[icon])

const brandIconList = Object
    .keys(BrandIcons)
    .filter(key => key !== "fab" && key !== "prefix")
    .map(icon => BrandIcons[icon])

library.add(...solidIconList, ...regularIconList, ...brandIconList);

// convert i tags to SVG
dom.watch({
    autoReplaceSvgRoot: document,
    observeMutationsRoot: document.body
});