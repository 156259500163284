import { getActiveChild } from "../functions/state";
import moment from 'moment';
import 'moment-timezone';

export const getActivities = (state) => {

    if (state.events) {

        let activities = new Set();

        let events = Object.entries(state.events).map(entry => {
            let event = entry[1];
            return {
                'id': event.subtitle.id,
                'title': event.subtitle.title,
                'type': event.type,
            }
        })
            .filter(entry => entry.type === 'activity' && entry.title)
            .forEach(obj => !activities.has(JSON.stringify(obj)) && activities.add(JSON.stringify(obj)));

        let activityFilter = [{
            'title': 'All Activities',
            'id': 0,
            'children': [...new Set([...activities].map(obj => JSON.parse(obj)))],
        }];

        return activityFilter;
    }

}

export const getAges = (state) => {

    if (state.events) {

        let ages = new Set();
        let ageFilters = [];
        let uniqueAges = new Set();

        let events = Object.entries(state.events).map(entry => {
            let event = entry[1]
            return {
                'title': event.subtitle,
                'type': event.type,
                'ages': event.ages,
            }
        })
            .filter(entry => entry.type === 'activity' && entry.title)
            .forEach(obj => !ages.has(JSON.stringify(obj)) && ages.add(JSON.stringify(obj)));

        ages.forEach(obj => ageFilters.push(JSON.parse(obj).ages));
        ageFilters.flat().forEach(obj => !uniqueAges.has(JSON.stringify(obj)) && uniqueAges.add(JSON.stringify(obj)));

        let ageFilter = [{
            'title': 'All Ages',
            'id': 0,
            'children': [...new Set([...uniqueAges].map(obj => JSON.parse(obj)))],
        }];

        return ageFilter;

    }

}

export const getLocations = (state) => {

    if (state.events) {

        let locations = new Set();

        let events = Object.entries(state.events).map(entry => {
            let event = entry[1];
            return {
                'id': event.location.id,
                'title': event.location.title,
                'type': event.type,
            }
        })
            .filter(entry => entry.type === 'activity' && entry.title)
            .forEach(obj => !locations.has(JSON.stringify(obj)) && locations.add(JSON.stringify(obj)));

        let locationFilter = [{
            'title': 'All Locations',
            'id': 0,
            'children': [...new Set([...locations].map(obj => JSON.parse(obj)))],
        }];

        return locationFilter;
    }

}

export const getChild = (state) => {
    if (state.fetchGrandchildren) {

        if (state.activeChild && state.navigation) {
            return getActiveChild(state.navigation, state.activeChild);
        }

    } else {

        return null;

    }
}

export const getClosure = (state) => {
    if ( state.closure ) {
        
        let closure = state.closure
        let startClosure, endClosure

        if ( closure.closureSchedule ) {
            if (closure.closureSchedule[0].closureStartTime && closure.closureSchedule[0].closureEndTime) {

                // create new date including time
                startClosure = moment(closure.closureSchedule[0].closureStartDate + ' ' + closure.closureSchedule[0].closureStartTime).tz("America/Denver");
                endClosure = moment(closure.closureSchedule[0].closureEndDate + ' ' + closure.closureSchedule[0].closureEndTime).tz("America/Denver");
                
            } else {

                // create new date without time
                startClosure = moment(closure.closureSchedule[0].closureStartDate).tz("America/Denver");
                endClosure = moment(closure.closureSchedule[0].closureEndDate).tz("America/Denver");

            }

            return {
                'id': closure.id,
                'title': closure.title,
                'schedule': closure.closureSchedule,
                'closureStart': startClosure,
                'closureEnd': endClosure,
                'hasTime': closure.closureSchedule ? closure.closureSchedule[0].closureStartTime && closure.closureSchedule[0].closureEndTime ? true : false : null,
                'information': closure.closureInformation,
                'closed': closure.closeImmediately,
            }
        }

    }
}

export const getClosures = (state) => {

    let filteredClosures = Object.entries(state.closures).map(entry => {
        let closure = entry[1]

        let startClosure, endClosure, displayClosure;

        let duration = []

        switch ( closure.notification ) {
            case 'P1D':
                duration = [
                    1,
                    'days'
                ]
                break
            case 'P2D': 
                duration = [
                    2,
                    'days'
                ]
                break
            case 'P3D': 
                duration = [
                    3,
                    'days'
                ]
                break
            case 'P1W': 
                duration = [
                    1,
                    'weeks'
                ]
                break
            case 'P2W': 
                duration = [
                    2,
                    'weeks'
                ]
                break
        }

        if (closure.closureSchedule) {
            if (closure.closureSchedule[0].closureStartTime && closure.closureSchedule[0].closureEndTime) {
                // create new date including time
                startClosure = moment(closure.closureSchedule[0].closureStartDate + ' ' + closure.closureSchedule[0].closureStartTime).tz("America/Denver");
                endClosure = moment(closure.closureSchedule[0].closureEndDate + ' ' + closure.closureSchedule[0].closureEndTime).tz("America/Denver");
                // subtract notification period
                displayClosure = moment(closure.closureSchedule[0].closureStartDate + ' ' + closure.closureSchedule[0].closureStartTime).subtract(duration[0], duration[1]).tz("America/Denver");
            } else {
                // create new date without time
                startClosure = moment(closure.closureSchedule[0].closureStartDate).tz("America/Denver");
                endClosure = moment(closure.closureSchedule[0].closureEndDate).tz("America/Denver");
                // subtract notification period
                displayClosure = moment(closure.closureSchedule[0].closureStartDate).subtract(duration[0], duration[1]).tz("America/Denver");
            }
        }

        return {
            'id': closure.id,
            'title': closure.title,
            'schedule': closure.closureSchedule,
            'closureStart': startClosure,
            'closureEnd': endClosure,
            'closureDisplay': displayClosure,
            'hasTime': closure.closureSchedule ? closure.closureSchedule[0].closureStartTime && closure.closureSchedule[0].closureEndTime ? true : false : null,
            'information': closure.closureInformation,
            'closed': closure.closeImmediately,
        }
    })
        .filter(closure => closure.closed || (moment().isAfter(closure.closureDisplay) && moment().isBefore(closure.closureEnd)));

    return filteredClosures.length ? filteredClosures : null;
}

export const getCsrf = (state) => {
    return state.csrf;
}

export const getEvents = (state) => {

    if (state.events) {

        // filter based on the selected categories
        let filteredEvents = Object.entries(state.events).map(entry => {
            let event = entry[1]
            return {
                'activityId': event.activityId,
                'ages': event.ages,
                'canceled': event.canceled,
                'classes': event.classes,
                'endDate': event.endDate,
                'eventId': event.eventId,
                'eventType': event.eventType,
                'id': event.id,
                'instructor': event.instructor,
                'location': event.location,
                'rawTitle': event.rawTitle,
                'startDate': event.startDate,
                'subtitle': event.subtitle,
                'title': event.title,
                'type': event.type,
                'ageIds': event.ages ? event.ages.map(cat => cat.id) : null,
            }
        });

        if (state.searchForm.activities.length) {
            filteredEvents = filteredEvents.filter(event => state.searchForm.activities.includes(event.activityId));
        }

        if (state.searchForm.ages.length) {
            filteredEvents = filteredEvents.filter(event => event.type !== 'closure').filter(event => event.type !== 'meeting').filter(event => event.type !== 'event').filter(event => state.searchForm.ages.some(ageId => event.ageIds.includes(ageId)))
        }

        if (state.searchForm.locations.length) {
            filteredEvents = filteredEvents.filter(event => state.searchForm.locations.includes(event.location.id));
        }

        if (state.searchForm.ages.length || state.searchForm.locations.length || state.searchForm.activities.length) {
            return filteredEvents;
        } else {
            return state.events;
        }

    }

}

export const getDailyEvents = (state) => {

    if (state.events) {

        const dailyTimes = {}

        // filter based on todays date
        let events = Object.entries(state.events).map(entry => {
            let event = entry[1]
            return {
                'id': event.eventId,
                'title': event.rawTitle,
                'canceled': event.canceled,
                'abbreviation': event.location.abbreviation,
                'date': moment(event.startDate).format("YYYY-MM-DD"),
                'today': moment(new Date()).format("YYYY-MM-DD"),
                'startTime': moment(event.startDate).format("h:mm A"),
                'sortTime': new Date(event.startDate.replace(/-/g, '/')),
            }
        })
        .filter(event => event.date === event.today)
        .sort(function(a, b) {
            return (b.sortTime < a.sortTime) ? 1 : (b.sortTime > a.sortTime) ? -1 : 0
        })

        events.forEach(event => (dailyTimes[event.id] ??= []).push({
            startTime: event.startTime,
            canceled: event.canceled,
        }))

        let uniqueDailyEvents = new Set();

        Object.entries(events).map(entry => {
            let event = entry[1]
            return {
                'id': event.id,
                'title': event.title,
                'abbreviation': event.abbreviation,
                'date': moment(event.startDate).format("YYYY-MM-DD"),
                'startTimes': dailyTimes[event.id],
            }
        })
        .forEach(obj => !uniqueDailyEvents.has(JSON.stringify(obj)) && uniqueDailyEvents.add(JSON.stringify(obj)));

        return [...new Set([...uniqueDailyEvents].map(obj => JSON.parse(obj)))];

    } else {

        return null;

    }

}

export const getEventDetail = (state) => {
    return state.eventDetail;
}

export const getFeaturedData = (state) => {
    return state.featuredData;
}

export const getFilters = (state) => {
    return state.filters;
}

export const getGqlToken = (state) => {
    return state.gqlToken;
}

export const getMenuOpen = (state) => {
    return state.menuOpen;
}

export const getNavigation = (state) => {
    return state.navigation;
}

export const getNews = (state) => {

    if (state.news.all) {

        let news = Object.entries(state.news.all).map(entry => {
            let news = entry[1]
            return {
                'title': news.title,
                'url': news.url,
                'featured': news.isFeatured,
                'description': news.description,
                'newsCategories': news.newsCategory.map(cat => cat.id),
                'teaserImage': news.teaserImage,
                'otherLinks': news.otherLinks,
            }
        })

        if (state.selectedCategories.news.length) {

            return news.filter(entry => state.selectedCategories.news.every(cat => entry.newsCategories.includes(cat)));

        } else {

            return news;

        }

    } else {

        return null;

    }

}

export const getNewsFeatured = (state) => {

    if (state.news.all) {

        let featuredNews = Object.entries(state.news.all).map(entry => {
            let news = entry[1]
            return {
                'title': news.title,
                'url': news.url,
                'featured': news.isFeatured,
                'description': news.description,
                'newsCategories': news.newsCategory.map(cat => cat.id),
                'teaserImage': news.teaserImage,
                'otherLinks': news.otherLinks,
            }
        }).filter(entry => entry.featured === true);

        if (state.selectedCategories.news.length) {

            return featuredNews.filter(entry => state.selectedCategories.news.every(cat => entry.newsCategories.includes(cat)));

        } else {

            return featuredNews;

        }

    } else {

        return null;

    }

}

export const getNewsRelated = (state) => {
    if (state.news.related) {
        return state.news.related;
    }
}

export const getNewsCategories = (state) => {
    return state.newsCategories;
}

export const getPlaceholders = (state) => {
    return state.placeholders;
}

export const getSelectedCategories = (state) => {
    return state.selectedCategories;
}

export const getSlides = (state) => {
    return state.slides;
}

export const getFeaturedStatuses = (state) => {
    return state.featuredStatuses;
}

export const getStatuses = (state) => {
    return state.statuses;
}

export const getSearchForm = (state) => {
    return state.searchForm;
}

export const getVersion = (state) => {
    return state.version;
}