export function getActiveChild(object, id) {

    let children = [];

    let items = Object.entries(object)
                    .map( entry => {
                        let item = entry[1];
                        return { 
                            ...item.children
                        }
                    });

    Object.values(items).forEach(item => {
        Object.values(item).forEach(child => {
            children.push(child);
        })
    });

    let child = children.filter(child => child.id === id);

    return child[0];
}