export const setActiveChild = (state, id) => {
    state.activeChild = id;
}

export const setClosures = (state, data) => {
    state.closures = data;
}

export const setClosure = (state, data) => {
    state.closure = data;
}

export const setCsrf = (state, data) => {
    state.csrf = data;
}

export const setEvents = (state, data) => {
    state.events = data;
}

export const setEventDetail = (state, data) => {
    state.eventDetail = data;
}

export const setFetchGrandchildren = (state, bool) => {
    state.fetchGrandchildren = bool;
}

export const setFeaturedData = (state, data) => {
    state.featuredData = data;
}

export const setFilters = (state, data) => {
    state.filters = data;
}

export const setGqlToken = (state, data) => {
    state.gqlToken = data;
}

export const setNavigation = (state, data) => {
    state.navigation = data;
}

export const setNews = (state, data) => {
    state.news.all = data;
}

export const setNewsRelated = (state, data) => {
    state.news.related = data;
}

export const setNewsCategories = (state, data) => {
    state.newsCategories = data;
}

export const setPlaceholders = (state, data) => {
    state.placeholders = data;
}

export const setSearch = (state, data) => {
    state.searchForm = data;
}

export const setSelectedCategories = (state, data) => {
    state.selectedCategories = data;
}

export const setSlides = (state, options) => {
    state.slides = {
        [options.id]: options.data,
    };
}

export const setStatuses = (state, data) => {
    state.statuses = data;
}

export const setFeaturedStatuses = (state, data) => {
    state.featuredStatuses = data;
}

export const setMenuOpen = (state, data) => {
    state.menuOpen = data;
}